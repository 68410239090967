.background-image {
  position: relative;
}
.background-image:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-image: url("../../assets/touristic.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  z-index: -1;
}
@media (max-width: 576px) {
  #basic-navbar-nav {
    background-color: white;
  }
}

.button-navigation {
  padding: 24px 0px;
}

.container-margin {
  margin: 0 90px;
}

.move-nav-dropdown .dropdown-menu {
  left: -30px;
}

.zize-title {
  margin-bottom: 42px;
  margin-top: 56px;
}

.title-home {
  display: flex;
  align-items: center;
  font-size: 6rem;
  flex-direction: column;
}
