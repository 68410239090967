.invitation-home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 95vh;
}

.travel-with-us {
  width: 100%;
  max-height: 500px;
  transition: all 0.5s;
}
.travel-with-us:hover {
  transform: scale(1.1);
}

.title-invitation {
  color: #955f1d;
  font-size: 4rem;
  font-family: "Bebas Neue", sans-serif;
}

.row-invitation {
  height: 100%;
}
